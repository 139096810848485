/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import Cookie from 'js-cookie';

import { setLang } from '../../../locales';

const SetLanguage = (props) => {
  const { langsymbol } = props.match.params;
  Cookie.set('lang', langsymbol);
  setLang(langsymbol);

  return <Redirect to="/" />;
};

export default withRouter(SetLanguage);
