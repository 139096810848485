import React from 'react';
import './animatedtick.scss';

const AnimatedTick = () => (
  <div className="animatedtick">
    <div className="tickline1" />
    <div className="tickline2" />
  </div>
);

export default AnimatedTick;
