/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import './questionpage.scss';

import useLang from '../../locales';
import BasicPopup from '../common/BasicPopup/BasicPopup';

import BACKEND_URLS from '../../constants/backendUrls';

const QuestionPage = (props) => {
  const [messages, setMessages] = useState([]);
  const [requireUserKey, setRequireUserKey] = useState(false);
  const [viewAccess, setViewAccess] = useState(false);
  const [userKey, setUserKey] = useState('');
  const [askerAccess, setAskerAccess] = useState(false);
  const [adminKey, setAdminKey] = useState('');
  const [miscPopupActive, setMiscPopupActive] = useState(false);
  const [miscPopupContent, setMiscPopupContent] = useState(<br />);

  const history = useHistory();

  const getQPageData = async (wanted) => {
    const response = await fetch(BACKEND_URLS.QPAGE_DATA_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        wanted,
        qpageid: props.match.params.id,
        userKey,
      }),
    });
    const data = await response.json();
    return data;
  };

  const loadMessages = async () => {
    const data = await getQPageData('MESSAGES');
    if (data.success) {
      setMessages(data.messages);
      // Scroll to bottom of messages when needed.
    }
  };

  const openMiscPopup = (content) => {
    setMiscPopupActive(true);
    setMiscPopupContent(content);
  };

  useEffect(() => {
    const asyncHolder = async () => {
      const data = await getQPageData('CHECK_VIEW_OPTION');
      if (data.success) {
        if (requireUserKey === false && data.keyNeededForView === true) {
          setRequireUserKey(true);
        }
      } else {
        history.push('/');
      }
    };
    asyncHolder();

    window.setAdmin = async (key) => {
      const response = await fetch(BACKEND_URLS.ADMIN_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          qpageid: props.match.params.id,
          key,
        }),
      });
      const data = await response.json();
      if (data.success === true) {
        // eslint-disable-next-line no-console
        console.log('Admin Login Success');
        setAdminKey(key);
        setMessages(data.messages);
      } else {
        // eslint-disable-next-line no-console
        console.log('Adming Login Failed');
      }
      // and update message list with additional params such as sender details
    };
    // eslint-disable-next-line
  }, []);

  // Fix for conditional calling
  const LANG = {
    userkey: useLang('userkey'),
    confirm: useLang('confirm'),
    skip: useLang('skip'),
  };

  const [userKeyFailMessage, setUserKeyFailMessage] = useState('');
  const [userKeyFailCount, setUserKeyFailCount] = useState(0);
  if (viewAccess === false) {
    const tryUserKey = async (event) => {
      event.preventDefault();
      const data = await getQPageData('CHECK_USER_KEY');
      if (data.success) {
        loadMessages();
        setAskerAccess(true);
        setViewAccess(true);
      } else {
        setUserKeyFailMessage('Invalid user key.');
        setUserKeyFailCount(userKeyFailCount + 1);
      }
    };
    const skipUserKey = () => {
      loadMessages();
      setViewAccess(true);
    };

    return (
      <BasicPopup>
        <form onSubmit={tryUserKey}>
          <div>
            Question ID:
            {' '}
            <strong>{props.match.params.id}</strong>
          </div>
          {userKeyFailMessage !== '' && <div className="statusmessage error">{`${userKeyFailMessage} (${userKeyFailCount})`}</div>}
          <input type="text" required placeholder={LANG.userkey} value={userKey} onChange={(event) => setUserKey(event.target.value)} />
          <div className="buttonrow">
            <button type="submit">{LANG.confirm}</button>
            {!requireUserKey && <button type="button" onClick={skipUserKey}>{LANG.skip}</button>}
          </div>
        </form>
      </BasicPopup>
    );
  }

  return (
    <div className="questionpage">
      <div className="topheader">
        <div className="sidenav">
          <button type="button" className="navoption" onClick={() => history.push('/')}>↷</button>
        </div>
        <div className="headbar">{`Question ID: ${props.match.params.id}`}</div>
      </div>
      <div className="messagelog">
        {
          messages.map((message) => (
            <div key={message.time} className={`messagerow from-${message.sender}`}>
              {message.sender === 'asker' && adminKey !== '' && <div className="senderdetails">{message.senderDetails}</div>}
              <div className="messagetime">
                {message.sender === 'replier' ? 'Eric' : message.senderName}
                {` (${message.time})`}
              </div>
              <div className="message">{message.message}</div>
            </div>
          ))
        }
        {askerAccess && <ReplyBox who="asker" questionId={props.match.params.id} userKey={userKey} adminKey={adminKey} messages={messages} setMessages={setMessages} openMiscPopup={openMiscPopup} />}
        {adminKey !== '' && <ReplyBox who="replier" questionId={props.match.params.id} userKey={userKey} adminKey={adminKey} messages={messages} setMessages={setMessages} openMiscPopup={openMiscPopup} />}
      </div>
      {
        miscPopupActive && (
          <BasicPopup>
            {miscPopupContent}
            <button type="button" onClick={() => setMiscPopupActive(false)}>Close</button>
          </BasicPopup>
        )
    }
    </div>
  );
};

const ReplyBox = ({
  who, questionId, userKey, adminKey, messages, setMessages, openMiscPopup,
}) => {
  const [message, setMessage] = useState('');

  const sendMessage = async (event) => {
    event.preventDefault();

    const senderName = '';

    const response = await fetch(BACKEND_URLS.SEND_MESSAGE_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        qpageid: questionId,
        senderName,
        who,
        message,
        userKey,
        adminKey,
      }),
    });
    const data = await response.json();
    if (data.success === true) {
      // Place the user message.
      setMessages([
        ...messages,
        {
          senderName,
          message,
          sender: who,
          time: new Date().toUTCString(),
        },
      ]);
      setMessage('');
    } else {
      // eslint-disable-next-line no-console
      console.log('sendmessage failed, reason:', data.reason);
      if (data.reason === 'REPLYING_TOO_FAST') {
        openMiscPopup(
          <div style={{ marginBottom: '16px' }}>
            <div style={{ color: 'red' }}>Failed to send message.</div>
            <div>
              <b>Reason:</b>
              {' '}
              {data.reason}
            </div>
            {data.remainingTime && (
              <div>
                <b>Remaining time:</b>
                {' '}
                {data.remainingTime}
                {' '}
                min
              </div>
            )}
          </div>,
        );
      }
    }
  };

  return (
    <div className={`messagerow from-${who} message-input`}>
      <form onSubmit={(event) => sendMessage(event, who)}>
        <div className="message">
          <textarea required rows="3" placeholder={useLang('replyhere')} value={message} onChange={(event) => setMessage(event.target.value)} />
        </div>
        <button type="submit">{useLang('send')}</button>
      </form>
    </div>
  );
};

export default withRouter(QuestionPage);
