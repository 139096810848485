let backendUrl = 'http://localhost:3000/backend';

if (process.env.NODE_ENV && process.env.NODE_ENV !== 'development') {
  backendUrl = process.env.REACT_APP_BACKEND_URL;
}

const BACKEND_URLS = {
  SUBMIT_URL: `${backendUrl}/submitquestion`,

  QPAGE_DATA_URL: `${backendUrl}/getqpagedata`,
  ADMIN_URL: `${backendUrl}/adminaccess`,
  SEND_MESSAGE_URL: `${backendUrl}/sendmessage`,
  SEND_UNLOAD: `${backendUrl}/sendunload`,
};

export default BACKEND_URLS;
