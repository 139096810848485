import Cookie from 'js-cookie';

import enLang from './en.json';
import plLang from './pl.json';

const getLanguageObject = (langsymbol, defaultLang = undefined) => {
  let language = defaultLang;
  if (langsymbol.includes('en')) {
    language = enLang;
  } else if (langsymbol.includes('pl')) {
    language = plLang;
  }

  return language;
};

let languageObject;

const cookieLanguage = Cookie.get('lang');
const browserLanguage = window.navigator.userLanguage || window.navigator.language;
if (cookieLanguage) {
  languageObject = getLanguageObject(cookieLanguage);
  if (typeof languageObject === 'undefined') {
    languageObject = getLanguageObject(browserLanguage, enLang);
  }
} else {
  languageObject = getLanguageObject(browserLanguage, enLang);
}


export const setLang = (langsymbol) => {
  languageObject = getLanguageObject(langsymbol, enLang);
};

const useLang = (param) => languageObject[param];

export default useLang;
