import React from 'react';
import './mainholder.scss';

const MainHolder = (props) => (
  <div className="mainholder">
    <div className="content">
      {props.children}
    </div>
  </div>
);

export default MainHolder;
