import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';

import useLang from './locales';
import MainHolder from './components/MainHolder/MainHolder';
import QuestionForm from './components/QuestionForm/QuestionForm';
// import PublicList from './components/PublicList/PublicList';
import QuestionPage from './components/QuestionPage/QuestionPage';
import SetLanguage from './components/common/SetLanguage/SetLanguage';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/">
            <MainHolder>
              <div className="title">
                <div className="titletop">Eryk Kołodziej</div>
                <div className="titlebottom">{useLang('ama')}</div>
              </div>
              <QuestionForm />
              {
                // <PublicList />
                // To be implemented
              }
            </MainHolder>
          </Route>
          <Route path="/qpage/:id">
            <MainHolder>
              <QuestionPage />
            </MainHolder>
          </Route>
          <Route path="/lang/:langsymbol">
            <SetLanguage />
          </Route>
          <Route path="*">
            404: Not Found
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
