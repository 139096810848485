/* eslint-disable react/prop-types */
import React from 'react';
import './basicpopup.scss';

const BasicPopup = (props) => {
  const { children } = props;
  return (
    <div className="basicpopupholder">
      <div className="popup">
        {children}
      </div>
    </div>
  );
};

export default BasicPopup;
