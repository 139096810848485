/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import './questionform.scss';

import useLang from '../../locales';
import BasicPopup from '../common/BasicPopup/BasicPopup';
import AnimatedTick from '../common/AnimatedTick/AnimatedTick';

import BACKEND_URLS from '../../constants/backendUrls';
import { QUESTIONFORM_CHECKBOXES } from './constants';

// eslint-disable-next-line no-unused-vars
const QuestionForm = (props) => {
  const [name, setName] = useState('');
  const [message, setMessage] = useState('');
  const [checkboxes, setCheckboxes] = useState({
    allowAllView: true,
    listPublic: false,
  });
  const [submitConfirmPopup, setSubmitConfirmPopup] = useState(false);
  const [userKey, setUserKey] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [previousChangeEvent, setPreviousChangeEvent] = useState({
    name: {},
    message: {},
  });
  const [trackedInput, setTrackedInput] = useState({
    name: [],
    message: [],
  });

  const submitForm = async (event) => {
    event.preventDefault();

    const response = await fetch(BACKEND_URLS.SUBMIT_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        targetReplier: 'doteric',
        name,
        message,
        checkboxes,
      }),
    });
    const data = await response.json();
    if (data.success === true) {
      setUserKey(data.userKey);
      setQuestionId(data.questionId);
      setSubmitConfirmPopup(true);
    }
  };

  const handleTextChange = (event, updateFunction) => {
    event.persist();
    setPreviousChangeEvent({
      ...previousChangeEvent,
      [event.target.id]: {
        time: Date.now(),
        message: event.target.value,
        inputType: event.nativeEvent.inputType,
      },
    });
    if (
      event.nativeEvent.inputType === 'deleteContentBackward'
      && previousChangeEvent[event.target.id].inputType !== 'deleteContentBackward'
    ) {
      const trackedInputData = trackedInput;
      trackedInputData[event.target.id].push(previousChangeEvent[event.target.id].message);
      setTrackedInput(trackedInputData);
    }
    updateFunction(event.target.value);
  };

  const onUnmount = () => {
    fetch(BACKEND_URLS.SEND_UNLOAD, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        trackedInput,
      }),
    });
  };

  useEffect(() => {
    window.addEventListener('beforeunload', onUnmount, false);
    return () => {
      window.removeEventListener('beforeunload', onUnmount, false);
      onUnmount();
    };
  // eslint-disable-next-line
  }, []);

  return (
    <div className="questionformholder">
      <form className="questionform" autoComplete="off" onSubmit={submitForm}>
        <div className="inputrow">
          <label htmlFor="name">
            <div className="inputname">{useLang('name')}</div>
            <input type="text" id="name" placeholder={useLang('nameplaceholder')} value={name} onChange={(event) => handleTextChange(event, setName)} />
          </label>
        </div>
        <div className="inputrow">
          <label htmlFor="message">
            <div className="inputname">{useLang('question')}</div>
            <textarea rows="5" id="message" placeholder={useLang('questionplaceholder')} required value={message} onChange={(event) => handleTextChange(event, setMessage)} />
          </label>
        </div>
        <div className="inputrow">
          {
            QUESTIONFORM_CHECKBOXES.map((checkbox) => (
              <CheckboxRow
                key={checkbox.name}
                checkboxes={checkboxes}
                setCheckboxes={setCheckboxes}
                checkboxName={checkbox.name}
                checkboxText={checkbox.text}
              />
            ))
          }
        </div>
        <div className="inputrow">
          <button type="submit">{useLang('send')}</button>
        </div>
      </form>
      {submitConfirmPopup && <SubmitConfirmPopup userKey={userKey} questionId={questionId} />}
    </div>
  );
};

const CheckboxRow = (props) => {
  const {
    checkboxes,
    setCheckboxes,
    checkboxName,
    checkboxText,
  } = props;

  const handleCheckbox = (event) => {
    setCheckboxes({
      ...checkboxes,
      [checkboxName]: event.target.checked,
    });
  };

  return (
    <label htmlFor={checkboxName} className="checkrow">
      <input id={checkboxName} type="checkbox" checked={checkboxes[checkboxName]} onChange={handleCheckbox} />
      <span className="checkmark" />
      <div className="checkname">{checkboxText}</div>
    </label>
  );
};

const SubmitConfirmPopup = (props) => {
  const [expandedContent, setExpandedContent] = useState(false);
  const { userKey, questionId } = props;
  const history = useHistory();

  const proceedToQuestion = () => {
    history.push(`/qpage/${questionId}`);
  };

  return (
    <BasicPopup>
      <div className="tickbox">
        <AnimatedTick />
      </div>
      <div className="poptitle">{useLang('questioncreated')}</div>
      <div className="userkeybox">
        <div className="userkeytext">{useLang('yourkeybelow')}</div>
        <input type="text" disabled value={userKey} />
      </div>
      <div className="confirmexpand">
        <button type="button" onClick={() => setExpandedContent(true)}>{useLang('clickproceed')}</button>
        <div
          className="expandedcontent"
          style={expandedContent
            ? { transform: 'scaleY(1.0)' }
            : {}}
        >
          <p>{useLang('confirmkeystored')}</p>
          <button type="button" onClick={proceedToQuestion}>{useLang('confirmproceed')}</button>
        </div>
      </div>
    </BasicPopup>
  );
};

export default QuestionForm;
